<template>
  <div>
    <page-header
      :title="'Company Info'"
      :sub-heading="true"
      :sub-header="'Dashboard / Company Info '"
      :link-name="'Company Models'"
      :link-url="'/company-models'"
    >
      <ca-button :type="'ghost'" @click.native="downloadPdf" class="mr-2">Download PDF</ca-button>
     <ca-button  @click.native="downloadXls()" class="mr-2">Download XSL</ca-button>
      <ca-button @click.native="confirmDelete()" :color="'danger'">Delete</ca-button>
    </page-header>

    <full-page-content-container class="mb-2">
      <p>{{company.name}}</p>
    </full-page-content-container>

    <full-page-content-container class="mb-2" ref="report">
       <ca-popup
            :header="'Delete Financial Modeling'"
            :toggle="openDeletePopup"
            @open="confirmDelete"
            @close="cancelDelete"
          >
            <template v-slot:content>
              Are you sure you want to delete this Financial Modeling?
            </template>
            <template v-slot:actions>
              <ca-button @click.native="deleteModal">Yes</ca-button>
            </template>
          </ca-popup>

      <p class="subject">Financial P&L Summary</p>
      <div class="table-conatiner watermark">
  
        <div class="table-row row-index">
          <div class="table-cell table-head">
            <span>
              StartUp Financial projection
            </span>
          
          </div>
          <div class="table-cell cell-index" :class="index % 2 == 0? '': 'gray'" v-for="(rowIndex, index) in rowIndexs" :key="index">
          {{rowIndex.name}}
          </div>
        </div>
        <div class="table-row row-value">
          <div class="table-wrap table-head" >
            <div class="cell" >
            Historical Period
            </div>
            <div class="years">
              <div class="year" :style="historics.length>0? 'width:50%': 'width:100%'" :class="index % 2 == 0? '': 'gray'">
                {{current.year}}
              </div>
             
              <div style="width:50%"  class="year" v-for="(historic, index) in historics" :key="index" :class="index % 2 == 0? '': 'gray'">
                {{historic.year}}
              </div>
             
            </div>
          </div>
            <div class="table-wrap" v-for="(rowIndex, index) in rowIndexs" :key="index" :class="index % 2 == 0? '': 'gray'">
              <div class="years">
              <div class="year-value" :style="historics.length>0? 'width:50%': 'width:100%'">
                {{scaled(current[rowIndex.field])}}
              </div>
              <div style="width:50%" class="year-value" v-for="(historic, ind) in historics" :key="ind">
                {{scaled(historic[rowIndex.field])}}
              </div>
            </div>
          </div>
        
        </div>
        <div class="table-row row-value">
          <div class="table-wrap table-head">
            <div class="cell">
            Projection Period
            </div>
            <div class="years">
              <div class="year" :style="historics.length>0? 'width:50%': 'width:100%'">
                {{current.year}}
              </div>
              <div style="width:50%" class="year" v-for="(projection, index) in projections" :key="index" >
                {{projection.year}}
              </div>
            </div>
          </div>
          <div class="table-wrap" v-for="(rowIndex, index) in rowIndexs" :key="index" :class="index % 2 == 0? '': 'gray'">
            <div class="years">
              <div class="year-value" :style="historics.length>0? 'width:50%': 'width:100%'">
                {{scaled(current[rowIndex.field])}}
              </div>
              <div style="width:50%" class="year-value" v-for="(projection, ind) in projections" :key="ind">
                {{scaled(projection[rowIndex.field])}}
              </div>
            </div>

          </div>
        </div>
      </div>
    </full-page-content-container>
    <full-page-content-container class="mb-2">
      <p class="subject">Financial Metrics Summary</p>
      <div class="table-conatiner watermark">
  
        <div class="table-row row-index">
          <div class="table-cell table-head">
            <span>
              StartUp Financial projection
            </span>
          
          </div>
          <div class="table-cell cell-index" :class="index % 2 == 0? '': 'gray'" v-for="(rowIndex, index) in rowIndexs2" :key="index">
          {{rowIndex.name}}
          </div>
        </div>
        <div class="table-row row-value">
          <div class="table-wrap table-head" >
            <div class="cell" >
            Historical Period
            </div>
            <div class="years">
              <div class="year" :style="historics.length>0? 'width:50%': 'width:100%'" :class="index % 2 == 0? '': 'gray'">
                {{current.year}}
              </div>
             
              <div style="width:50%"  class="year" v-for="(historic, index) in historics" :key="index" :class="index % 2 == 0? '': 'gray'">
                {{historic.year}}
              </div>
             
            </div>
          </div>
            <div class="table-wrap" v-for="(rowIndex, index) in rowIndexs2" :key="index" :class="index % 2 == 0? '': 'gray'">
              <div class="years">
              <div class="year-value" :style="historics.length>0? 'width:50%': 'width:100%'">
                {{scaled(current[rowIndex.field])}}
              </div>
              <div style="width:50%" class="year-value" v-for="(historic, ind) in historics" :key="ind">
                {{scaled(historic[rowIndex.field])}}
              </div>
            </div>
          </div>
        
        </div>
        <div class="table-row row-value">
          <div class="table-wrap table-head">
            <div class="cell">
            Projection Period
            </div>
            <div class="years">
              <div class="year" :style="historics.length>0? 'width:50%': 'width:100%'">
                {{current.year}}
              </div>
              <div style="width:50%" class="year" v-for="(projection, index) in projections" :key="index" >
                {{projection.year}}
              </div>
            </div>
          </div>
          <div class="table-wrap" v-for="(rowIndex, index) in rowIndexs2" :key="index" :class="index % 2 == 0? '': 'gray'">
            <div class="years">
              <div class="year-value" :style="historics.length>0? 'width:50%': 'width:100%'">
                {{scaled(current[rowIndex.field])}}
              </div>
              <div style="width:50%" class="year-value" v-for="(projection, ind) in projections" :key="ind">
                {{scaled(projection[rowIndex.field])}}
              </div>
            </div>

          </div>
        </div>
      </div>
    </full-page-content-container>

    <full-page-content-container class="mb-2">
      <p class="chart-subject"> Projection Year chart </p>
           <vue-apex-charts
            :options="options"
            :series="series"
            height="350"
            class="watermark"
          ></vue-apex-charts>
    </full-page-content-container>

    <vue-html2pdf  
      :filename="'Model-' + $route.params.id + '.pdf'"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a3"
      pdf-orientation="landscape"
      id="PdfPrint"
      ref="pdf"
          
         >
         <section slot="pdf-content" style="width:1300px">
          <section slot="pdf-item" style="padding:40px 26px;color:black;position:relative">
            <img :src="avatar" height="200" style="max-width:70%;margin: 300px auto 0;display:block" />
              <h2 style="width:100%;color:black;text-align:center;font-size:1.5rem;font-weight:bold;margin-top:20px;letter-spacing:1px"
                >Financial Projection For {{company?company.name:'' }}</h2
              >
          <h5 style="width:100%;text-align:center;font-weight:bold;margin-top:15px">{{this.months[new Date().getMonth()]+" "+new Date().getFullYear()}}</h5>
          <img src="@/assets/images/logo/logo.svg" width="170" style="margin-top:320px;padding-left:25px;" />
          <h5 style="margin:15px 0 0 0;padding:0 0 0 25px;font-weight:bold;color:#444444;font-size:1.1rem;letter-spacing:0.5px"
            >This model was generated on <a href="www.caena.io" style="color:#1D817F">Caena.io</a></h5
          >
        </section>
        <section slot="pdf-item" style="padding:0px 15px;color:black;position:relative; height:1000px; width:1300px; margin-top:200px;" >
        
          <p class="subject">Financial P&L Summary</p>
          <div class="table-conatiner watermark">
      
            <div class="table-row row-index">
              <div class="table-cell table-head">
                <span>
                  StartUp Financial projection
                </span>
              
              </div>
              <div class="table-cell cell-index" v-for="(rowIndex, index) in rowIndexs" :key="index" :class="index % 2 == 0? '': 'gray'" >
              {{rowIndex.name}}
              </div>
            </div>
            <div class="table-row row-value">
              <div class="table-wrap table-head" >
                <div class="cell" >
                Historical Period
                </div>
                <div class="years">
                  <div class="year" :style="historics.length>0? 'width:50%': 'width:100%'" :class="index % 2 == 0? '': 'gray'">
                    {{current.year}}
                  </div>
                
                  <div style="width:50%"  class="year" v-for="(historic, index) in historics" :key="index" :class="index % 2 == 0? '': 'gray'">
                    {{historic.year}}
                  </div>
                
                </div>
              </div>
                <div class="table-wrap" v-for="(rowIndex, index) in rowIndexs" :key="index" :class="index % 2 == 0? '': 'gray'">
                  <div class="years">
                  <div class="year-value" :style="historics.length>0? 'width:50%': 'width:100%'">
                    {{scaled(current[rowIndex.field])}}
                  </div>
                  <div style="width:50%" class="year-value" v-for="(historic, index) in historics" :key="index">
                    {{scaled(historic[rowIndex.field])}}
                  </div>
                </div>
              </div>
            
            </div>
            <div class="table-row row-value">
              <div class="table-wrap table-head">
                <div class="cell">
                Projection Period
                </div>
                <div class="years">
                  <div class="year" :style="historics.length>0? 'width:50%': 'width:100%'">
                    {{current.year}}
                  </div>
                  <div style="width:50%" class="year" v-for="(projection, index) in projections" :key="index" >
                    {{projection.year}}
                  </div>
                </div>
              </div>
              <div class="table-wrap" v-for="(rowIndex, index) in rowIndexs" :key="index" :class="index % 2 == 0? '': 'gray'">
                <div class="years">
                  <div class="year-value" :style="historics.length>0? 'width:50%': 'width:100%'">
                    {{scaled(current[rowIndex.field])}}
                  </div>
                  <div style="width:50%" class="year-value" v-for="(projection, index) in projections" :key="index">
                    {{scaled(projection[rowIndex.field])}}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section slot="pdf-item" style="padding:0px 15px;color:black;position:relative; width:1300px; margin:auto;">
          <p class="subject">Financial Metrics Summary</p>
          <div class="table-conatiner watermark">
      
            <div class="table-row row-index">
              <div class="table-cell table-head">
                <span>
                  StartUp Financial projection
                </span>
              
              </div>
              <div class="table-cell cell-index" :class="index % 2 == 0? '': 'gray'" v-for="(rowIndex, index) in rowIndexs2" :key="index">
              {{rowIndex.name}}
              </div>
            </div>
            <div class="table-row row-value">
              <div class="table-wrap table-head" >
                <div class="cell" >
                Historical Period
                </div>
                <div class="years">
                  <div class="year" :style="historics.length>0? 'width:50%': 'width:100%'" :class="index % 2 == 0? '': 'gray'">
                    {{current.year}}
                  </div>
                
                  <div style="width:50%"  class="year" v-for="(historic, index) in historics" :key="index" :class="index % 2 == 0? '': 'gray'">
                    {{historic.year}}
                  </div>
                
                </div>
              </div>
                <div class="table-wrap" v-for="(rowIndex, index) in rowIndexs2" :key="index" :class="index % 2 == 0? '': 'gray'">
                  <div class="years">
                  <div class="year-value" :style="historics.length>0? 'width:50%': 'width:100%'">
                    {{scaled(current[rowIndex.field])}}
                  </div>
                  <div style="width:50%" class="year-value" v-for="(historic, index) in historics" :key="index">
                    {{scaled(historic[rowIndex.field])}}
                  </div>
                </div>
              </div>
            
            </div>
            <div class="table-row row-value">
              <div class="table-wrap table-head">
                <div class="cell">
                Projection Period
                </div>
                <div class="years">
                  <div class="year" :style="historics.length>0? 'width:50%': 'width:100%'">
                    {{current.year}}
                  </div>
                  <div style="width:50%" class="year" v-for="(projection, index) in projections" :key="index" >
                    {{projection.year}}
                  </div>
                </div>
              </div>
              <div class="table-wrap" v-for="(rowIndex, index) in rowIndexs2" :key="index" :class="index % 2 == 0? '': 'gray'">
                <div class="years">
                  <div class="year-value" :style="historics.length>0? 'width:50%': 'width:100%'">
                    {{scaled(current[rowIndex.field])}}
                  </div>
                  <div style="width:50%" class="year-value" v-for="(projection, index) in projections" :key="index">
                    {{scaled(projection[rowIndex.field])}}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        <section slot="pdf-item" style="padding:40px 26px;color:black;position:relative; height:auto; width:1300px; margin:auto">
            <p class="chart-subject">Projection Year chart</p>
            <vue-apex-charts
              :options="options"
              :series="series"
              height="350"
              class="watermark"
            ></vue-apex-charts>
        </section>
      </section>
   </vue-html2pdf>
  </div>

   
</template>

<script>
// import Currencies from "./settings/Currencies.js";
import Symbols    from "./settings/Symbols.js";
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import CaButton from "../../components/buttons/CaButton";
import FullPageContentContainer from "../../layouts/components/fullPage/FullPageContentContainer";
import VueApexCharts  from "vue-apexcharts";
import axios from 'axios';
import CaPopup from "../../components/popup/CaPopup";
import VueHtml2pdf from 'vue-html2pdf';
import fileSaver      from "file-saver";
export default {
  name: "CompanyModels",
  components: { FullPageContentContainer, CaButton, PageHeader, VueApexCharts, CaPopup, VueHtml2pdf },
  data() {
    return {
      scales:
        [
          { icon: 'None', text:'None',  value:1 },
          { icon: '1 k',  text:'Thousands', value:1000 },
          { icon: '1 M',  text:'Milions',   value:1000000 },
          { icon: '1 B',  text:'Bilions',   value:1000000000 }
        ],
      series:
        [
          { name: "EBITDA",     type:'bar',  data:[] },
          { name: "Revenue",     type:'bar',  data:[] },
          { name: "Net Profit",     type:'line',  data:[] },
        ],
        options:
        {
          fill: { opacity: 1 },
          plotOptions: { bar: { rangeBarGroupRows: false, columnWidth: '50%', distributed: false, } },
          colors: ["#3DD598", "#FFBC2C", "#1d817f"],
          chart: { height: 350, zoom: { enabled: false }, toolbar: { show: (this.setup && this.allow) ? true : false } },
          dataLabels: { enabled: false },
          stroke: { colors: ['#fff','#fff','#1d817f'], curve: ["stepline","stepline","straight"], width: [3,3,4] },
          markers: { colors: ['#CCCCCC'], strokeColors: '#1d817f', strokeWidth: 3, size: [3], hover: { sizeOffset: 2 } },
          legend: { itemMargin: { horizontal: 10, vertical: 15 } },
          grid: { padding: { top: 20, bottom: 0 }, row: { opacity: 0.75 } },
          xaxis: { categories: [], tickPlacement: 'between', position: "bottom", title: false, labels: { offsetY: 2 } },
          yaxis:
          [
            { seriesName: 'A', tickAmount: 5, axisTicks: { show: true }, axisBorder: { show: true, }, labels:{} },
            { seriesName: 'A', tickAmount: 5, show: false, labels:{} },
            { seriesName: 'B', tickAmount: 5, opposite: true, labels:{} }
          ],
          responsive:
          [
            { breakpoint: 768,  options: { legend: { position: 'top', horizontalAlign: 'left', itemMargin: { vertical: 10 } }, grid: { padding: { top:20, bottom: 20 } } } },
            { breakpoint: 4096, options: { legend: { position: 'top', horizontalAlign: 'right', itemMargin: { vertical: 0 } }, grid: { padding: { top:20, bottom: 0 } } } }
          ]
        },
      rowIndexs:[
        {
          name: "Revenue",
          field: "sales"
        },
        {
          name: "COGS",
          field: "cost_of_goods_sold"
        },
        {
          name: "Gross Profit",
          field: "grossProfit"
        },
        {
          name: "SG&A",
          field: "selling_general_administrative"
        },
        {
          name: "EBITDA",
          field: "ebitda"
        },
        {
          name: "D&A",
          field: "depreciation_amortization"
        },
        {
          name: "EDBIT",
          field: "interest_value"
        },
        {
          name: "PBT",
          field: "ebit"
        },
        {
          name: "Taxes",
          field: "taxes"
        },
           {
          name: "Net Profit (Loss)",
          field: "ebiat"
        },
      ],
      rowIndexs2:[
        {
          name: "Revenue Growth",
          field: "sales"
        },
        {
          name: "Gross Profit Margin",
          field: "grossProfitMargin"
        },
        {
          name: "EBITDA Margin",
          field: "ebitdaMargin"
        },
        {
          name: "Net Profit Margin",
          field: "ebitMargin"
        },
        {
          name: "Taxes",
          field: "taxes"
        },
      ],
      months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      month_short: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
      avatar: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII=',
      company:[],
      historics:[],
      current:null,
      projections:null,
      model_currency:'',
      business_stage: '',
      openDeletePopup:false,
      historical_years:0,
      projection_years:0,
      PnlRevenueMode    : !1,
      PnlRevenueMoPr    : !1,
      PnlRevenueMoSa    : !1,
      PnlRevenueData    : [],
      PnlRevenueList    : [],
      PnlCostCOGSData   : [],
      PnlCostCOGSList   : [],
      PnlCostSGAData    : [],
      PnlCostSGAList    : [],
      PnlCostDAList     : [],
      PnlCostDAData     : [],
      PnlCostNIData     : [],
      PnlCostNIList     : [],
      model:null,

    }
  },
  methods: {
    async getFinancialDaTa(){
      const loading = this.$vs.loading();
      axios.defaults.headers.common["Authorization"] =
        this.$store.state.accessToken;
      axios.get(`${this.$store.state.server.requestUri}/admin/show-single-calculation/${this.$route.params.id}`)
        .then((res) => {
          this.model = res.data.data;
          this.company = res.data.data.company;
          this.historics = res.data.data.calculation[0].dfc_calculation.historic;
          this.historical_years = res.data.data.calculation[0].dfc_calculation.historic.length;
          this.current = res.data.data.calculation[0].dfc_calculation.current;
          this.projections = res.data.data.calculation[0].dfc_calculation.projection[0];
          this.projection_years = res.data.data.calculation[0].dfc_calculation.projection.length;
          this.model_currency = res.data.data.inputs.model_currency
          this.business_stage = res.data.data.inputs[0].business_stage;
            this.options.xaxis.categories.push(this.current.type);
            this.series[0].data.push(this.current.ebitda);
            this.series[1].data.push(this.current.sales);
            this.series[2].data.push(this.current.ebiat);
  
          for (const i in this.projections) {
            this.options.xaxis.categories.push(this.projections[i].type);
            this.series[0].data.push(this.projections[i].ebitda);
            this.series[1].data.push(this.projections[i].sales);
            this.series[2].data.push(this.projections[i].ebiat);
            // console.log(this.projections);
        
          }
          loading.close();

            // this.options.yaxis[0].labels.formatter = value => { return ( this.formatted(value,true) )};
            // this.options.yaxis[1].labels.formatter = value => { return ( this.formatted(value,true) )};
            // this.options.yaxis[2].labels.formatter = value => { return ( this.formatted(value,true) )}
          
        })
        .catch((err) => console.log(err));
    },
    getCalculationName (model=null, id)
    {
      let name =''
      try
      {
        name = 'ID '+model.calculated.id;

        if (model.input_current.title)
        {
          let title = JSON.parse(model.input_current.title);
          if (title)
          {
            name = title[id]
          }
        }
      }
      catch($)
      {
        name = 'Model'
      }

      if(name=='ID 0') name = 'ID '+model.calculated.id;

      return name.replace(/(.{16})..+/, "$1.")

    },
    scaled(number)
    {
      if ( isNaN(number) ) return 0;

      number = number /1;
      number = number.toLocaleString(undefined, {minimumFractionDigits: 0,maximumFractionDigits: 1});

      return number
    },
    formatted(number,currency)
    {
      if ( isNaN(number) ) return 0;

      number = number.toLocaleString(undefined, {minimumFractionDigits: 0,maximumFractionDigits: 1});

      if ( currency )
      {
        number = Symbols[this.model_currency] + number;
      }
      return number
    },
    scaledLabel(number)
    {
      if ( isNaN(number) ) return 0;

      number = Symbols[this.model_currency] + Math.round(number / this.range);
      number = number.toLocaleString(undefined, {minimumFractionDigits: 0,maximumFractionDigits: 1});
      number = number+this.scaleLabels[this.range];

      return number
    },
    async getScale()
    {
      this.scaleLabels             = [];
      this.scaleLabels[1]          = '';
      this.scaleLabels[1000]       = 'k';
      this.scaleLabels[1000000]    = 'M';
      this.scaleLabels[1000000000] = 'B';
    },

     getCompanyName()
    {
      return this.model.company.name.replace(/(.{20})..+/, "$1.")
    },

    cancelDelete() {
      this.openDeletePopup = false;
    },
    confirmDelete() {
      this.openDeletePopup = true;
    },


    deleteModal(){
      const loading = this.$vs.loading();
      axios
        .delete(
          `${this.$store.state.server.requestUri}/admin/delete-single-calculation/${this.$route.params.id}`
        )
        .then(() => {
          loading.close();
          this.$toast(
            `Calculation with id ${this.$route.params.id} deleted successfully`,
            {
              position: "top-center",
              type: "success",
            }
          );
          // console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.$toast(`An error occurred while deleting calculation`, {
            position: "top-center",
            type: "error",
          });
          console.log(err.response);
        });
    },
    downloadPdf() {
    const loading = this.$vs.loading();
    this.$refs.pdf.generatePdf();
    loading.close();
  },
  setXlsRow ($row)
    {
      return $row % 2 ? this.$_XLS_FILL_EVEN : this.$_XLS_FILL_ODD;
    },
   downloadXls (_row=0,i_row=15){


      this.$toast("Download XLS...");

      const columns = ['A','B','C','D','E','F','G','H','I']

      const Excel     = require('exceljs');
      const workbook  = new Excel.Workbook();
      var $col_global = 0;

      workbook.creator        = 'https://www.caena.io';
      workbook.lastModifiedBy = 'CAENA';
      workbook.created        = new Date();
      workbook.modified       = new Date();
      workbook.lastPrinted    = new Date();

      let i_start  = this.current.year - this.historical_years
      let i_column = 2;
          i_column += this.historical_years;
          i_column += this.projection_years;

      const S = workbook.addWorksheet ( 'Financial Model', { views: [{showGridLines: true}], pageSetup: { verticalCentered:true, horizontalCentered:true } } );

      S.properties.defaultColWidth = 23;
      S.properties.defaultRowHeight = 39;

      S.getColumn(1).width = 30;

      for (let i = 1; i <= i_column; i++)
      {
        if(i>1) S.getColumn(i).width = 18;

        if((i-1)>0)
        {
          S.getCell(columns[i-1]+'5').value = i_start+(i-2);
          S.getCell(columns[i-1]+'5').font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
          S.getCell(columns[i-1]+'5').alignment = this.$_XLS_ALIGNMENT_RIGHT;
        }

        S.getCell(columns[i-1]+'4').fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'eeece1'} };
        S.getCell(columns[i-1]+'5').fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'eeece1'} };
      }


      i_row += ( this.PnlRevenueData.length+this.PnlCostCOGSData.length+this.PnlCostSGAData.length+this.PnlCostDAData.length+this.PnlCostNIData.length );

      if(this.business_stage==='early traction')
      i_row += 2;

      S.addRow([""]);
      S.getRow(1).height = 48;
      S.addRow([""]);
      S.getRow(2).height = 43;
      S.addRow([""]);
      S.getRow(3).height = 27;

      for ( let X=1; X <= i_column; X++ )
      for ( let Y=1; Y <= 3; Y++ )
      S.getCell(columns[X-1]+Y).fill = this.setXlsRow($row);

      for ( let X=1; X<=i_column; X++ )
      {
        for( let Y=4; Y<=i_row; Y++ )
        {
          if( Y<7 )
          {
            S.getCell(columns[X-1]+Y).border =
            {
              top:   {style:'thin',color:{argb:'A5A5A5'} },
              right: {style:'thin',color:{argb:'A5A5A5'} }
            }
          }
          else
          {
            S.getCell(columns[X-1]+Y).border =
            {
              right: {style:'thin',color:{argb:'A5A5A5'}}
            }
          }
        }
      }

      S.mergeCells('A1:'+columns[i_column-1]+'2');
      S.mergeCells('A3:'+columns[i_column-1]+'3');

      for( let Y=1; Y<=3; Y++ )
      {
        S.getCell(columns[i_column-1]+Y).border =
        {
          right: {style:'thin',color:{argb:'A5A5A5'} }
        }
      }

      S.getCell('A1').value='Financial Projection For '+this.getCompanyName()+', '+this.months[new Date().getMonth()]+" "+new Date().getFullYear();
      S.getCell('A1').alignment = this.$_XLS_ALIGNMENT_LEFT;
      S.getCell('A1').font = { size:15, bold: true,name:'Calibri' };
      S.getCell('A1').fill = this.setXlsRow($row);

      S.getCell('A4').value="Currency = "+this.model.inputs[0].model_currency;
      S.getCell('A4').font = { color: { argb: '123F4E' }, bold: true, name:'Calibri'};
      S.getCell('A4').alignment = this.$_XLS_ALIGNMENT_LEFT;

      if(this.historical_years>0)
      {
        S.getCell('B4').value="Historical Period";
        S.getCell('B4').font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
        S.getCell('B4').alignment = { vertical: 'middle', horizontal: 'center' };

        S.getCell(columns[1+this.historical_years]+'4').value="Projection Period";
        S.getCell(columns[1+this.historical_years]+'4').font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
        S.getCell(columns[1+this.historical_years]+'4').alignment = { vertical: 'middle', horizontal: 'center' };

        if(this.historical_years===2)
        {
          S.mergeCells('B4:C4');
        }
      }
      else
      {
        S.getCell('B4').value="Projection Period";
        S.getCell('B4').font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
        S.getCell('B4').alignment = { vertical: 'middle', horizontal: 'center' };
      }

      S.mergeCells(columns[1+this.historical_years]+'4:'+columns[i_column-1]+'4');

      let $col = 0;
      let $row = 6;

      for( let l=0;l<this.PnlRevenueData.length;l++)
      {
        S.getCell(columns[0]+$row).value     = '  '+this.PnlRevenueData[l].name;
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_ITALIC;
        $row++;
      }

      if(this.PnlRevenueData.length>0)
      S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_BOLD;
      S.getCell(columns[0]+$row).value     = 'Revenue';
      S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
      S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
      $row++;

      for( let l=0;l<this.PnlCostCOGSData.length;l++)
      {
        S.getCell(columns[0]+$row).value     = '  '+this.PnlCostCOGSData[l].name;
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_ITALIC;
        $row++;
      }

      S.getCell(columns[0]+$row).value     = 'Cost of Goods Sold';
      S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
      S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
      $row++;

      S.getCell(columns[0] + $row).value = 'Gross Profit';
      S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
      S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
      if(this.business_stage==='ideation')
      S.getCell(columns[0] + $row).font = this.$_XLS_FONT_BOLD;
      $row++;

      if(this.business_stage==='early traction')
      {
        S.getCell(columns[0]+$row).value     = 'Gross Profit Margin';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_BOLD;
        $row++;
      }

      for( let l=0;l<this.PnlCostSGAData.length;l++)
      {
        S.getCell(columns[0]+$row).value     = '  '+this.PnlCostSGAData[l].name;
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_ITALIC;
        $row++;
      }

      S.getCell(columns[0]+$row).value     = 'Selling General & Administrative';
      S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
      S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
      $row++;

      S.getCell(columns[0]+$row).value     = 'EBITDA';
      S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
      S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
      if(this.business_stage==='ideation')
      S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_BOLD;
      $row++;

      if(this.business_stage==='early traction')
      {
        S.getCell(columns[0]+$row).value     = 'EBITDA Margin';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_BOLD;
        $row++;
      }

      for( let l=0;l<this.PnlCostDAData.length;l++)
      {
        S.getCell(columns[0]+$row).value     = '  '+this.PnlCostDAData[l].name;
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_ITALIC;
        $row++;
      }

      S.getCell(columns[0]+$row).value     = 'Depreciation & Amortization';
      S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
      S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
      $row++;

      for( let l=0;l<this.PnlCostNIData.length;l++)
      {
        S.getCell(columns[0]+$row).value     = '  '+this.PnlCostNIData[l].name;
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_ITALIC;
        $row++;
      }

      S.getCell(columns[0]+$row).value     = 'Net Interest';
      S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
      S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
      $row++;
      S.getCell(columns[0]+$row).value     = 'Profit Before Tax';
      S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
      S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
      S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_BOLD;
      $row++;
      S.getCell(columns[0]+$row).value     = 'Taxes';
      S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
      S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
      $row++;
      S.getCell(columns[0]+$row).value     = 'Profit After Tax';
      S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
      S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
      S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_BOLD;

      for (let i=1; i <= this.historical_years; i++)
      {

        $row = 6;
        $col = i;

        for( let l=0;l<this.PnlRevenueData.length;l++)
        {
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_ITALIC;
          $row++;
        }

        S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[i-1].sales);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        if(this.PnlRevenueData.length>0)
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        $row++;

        for( let l=0;l<this.PnlCostCOGSData.length;l++)
        {
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_ITALIC;
          $row++;
        }

        S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[i-1].cost_of_goods_sold);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[i-1].grossProfit);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        if(this.business_stage==='ideation')
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        $row++;

        if(this.business_stage==='early traction')
        {
          S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[i-1].grossProfitMargin);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          $row++;
        }

        for( let l=0;l<this.PnlCostSGAData.length;l++)
        {
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_ITALIC;
          $row++;
        }

        S.getCell(columns[$col]+$row).value     = this.formatted(this.historic[i-1].selling_general_administrative);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(this.historic[i-1].ebitda);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);

        if(this.business_stage==='ideation')
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        $row++;

        if(this.business_stage==='early traction')
        {
          S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[i-1].ebitdaMargin);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          $row++;
        }

        for( let l=0;l<this.PnlCostDAData.length;l++)
        {

          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_ITALIC;
          $row++;

        }

        S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[i-1].depreciation_amortization);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;

        for( let l=0;l<this.PnlCostNIData.length;l++)
        {
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_ITALIC;
          $row++;
        }

        S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[i-1].interest_value);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[i-1].ebit);
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[i-1].taxes);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[i-1].ebiat);
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);

      }

      $col = this.historical_years*1+1;
      $row = 6;

      for( let l=0;l<this.PnlRevenueData.length;l++)
      {
        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.PnlRevenueData[l].revenue_calculation)[this.current.year]);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
      }

      S.getCell(columns[$col]+$row).value     = this.formatted(this.current.sales);
      S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
      S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
      if(this.PnlRevenueData.length>0)
      S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
      $row++;

      for( let l=0;l<this.PnlCostCOGSData.length;l++)
      {
        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.PnlCostCOGSData[l].cost_calculation)[this.current.year]);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
      }

      S.getCell(columns[$col]+$row).value     = this.formatted(this.current.cost_of_goods_sold);
      S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
      S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
      $row++;
      S.getCell(columns[$col]+$row).value     = this.formatted(this.current.grossProfit);
      S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
      S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);

      if(this.business_stage==='ideation')
      S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
      $row++;

      if(this.business_stage==='early traction')
      {
        S.getCell(columns[$col]+$row).value     = this.formatted(this.current.grossProfitMargin);
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
      }

      for( let l=0;l<this.PnlCostSGAData.length;l++)
      {
        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.PnlCostSGAData[l].cost_calculation)[this.current.year]);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
      }

      S.getCell(columns[$col]+$row).value     = this.formatted(this.current.selling_general_administrative);
      S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
      S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
      $row++;
      S.getCell(columns[$col]+$row).value     = this.formatted(this.current.ebitda);
      S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
      S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
      if(this.business_stage==='ideation')
      S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
      $row++;

      if(this.business_stage==='early traction')
      {
        S.getCell(columns[$col]+$row).value     = this.formatted(this.current.ebitdaMargin);
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
      }

      for( let l=0;l<this.PnlCostDAData.length;l++)
      {
        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.PnlCostDAData[l].cost_calculation)[this.current.year]);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
      }

      S.getCell(columns[$col]+$row).value     = this.formatted(this.current.depreciation_amortization);
      S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
      S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
      $row++;

      for( let l=0;l<this.PnlCostNIData.length;l++)
      {
        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.PnlCostNIData[l].cost_calculation)[this.current.year]);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
      }

      S.getCell(columns[$col]+$row).value     = this.formatted(this.current.interest_value);
      S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
      S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
      $row++;
      S.getCell(columns[$col]+$row).value     = this.formatted(this.current.ebit);
      S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
      S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
      S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
      $row++;
      S.getCell(columns[$col]+$row).value     = this.formatted(this.current.taxes);
      S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
      S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
      $row++;
      S.getCell(columns[$col]+$row).value     = this.formatted(this.current.ebiat);
      S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
      S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
      S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);

      for (let i = 1; i <= this.projections.length; i++)
      {
        $col++;
        $row = 6;

        

        for( let l=0;l<this.PnlRevenueData.length;l++)
        {
          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.PnlRevenueData[l].revenue_calculation)[this.current.year+i]);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          $row++;
        }

        S.getCell(columns[$col]+$row).value     = this.formatted(this.projections[i+1].sales);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        if(this.PnlRevenueData.length>0)
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        $row++;

        for( let l=0;l<this.PnlCostCOGSData.length;l++)
        {
          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.PnlCostCOGSData[l].cost_calculation)[this.current.year+i]);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          $row++;
        }

        S.getCell(columns[$col]+$row).value     = this.formatted(this.PnlCostCOGSList[this.current.year+i]);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.projections[i+1].grossProfit);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        if(this.business_stage==='ideation')
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        $row++;

        // if(this.business_stage==='early traction')
        {
          S.getCell(columns[$col]+$row).value     = this.formatted(this.projections[i+1].grossProfitMargin);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
          $row++;
        }

        for( let l=0;l<this.PnlCostSGAData.length;l++)
        {
          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.PnlCostSGAData[l].cost_calculation)[this.current.year+i]);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          $row++;
        }

        S.getCell(columns[$col]+$row).value     = this.formatted(this.PnlCostSGAList[this.current.year+i]);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.projections[i+1].ebitda);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        if(this.business_stage==='ideation')
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        $row++;

        if(this.business_stage==='early traction')
        {
          S.getCell(columns[$col]+$row).value     = this.formatted(this.projections[i+1].ebitdaMargin);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
          $row++;
        }

        for( let l=0;l<this.PnlCostDAData.length;l++)
        {
          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.PnlCostDAData[l].cost_calculation)[this.current.year+i]);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          $row++;
        }

        S.getCell(columns[$col]+$row).value     = this.formatted(this.PnlCostDAList[this.current.year+i]);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;

        for( let l=0;l<this.PnlCostNIData.length;l++)
        {
          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.PnlCostNIData[l].cost_calculation)[this.current.year+i]);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          $row++;
        }

        S.getCell(columns[$col]+$row).value     =  this.formatted(this.PnlCostNIList[this.current.year+i]);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.projections[i+1].ebit);
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.projections[i+1].taxes);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.projections[i+1].ebiat);
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
      }

      for (let X= 1; X <= i_column; X++)
      S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT

      $row++;

      S.mergeCells('A'+$row+':'+columns[i_column-1]+$row);

      for (let X= 1; X <= i_column; X++)
      S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT;

      $row++;

      for (let i = 1; i <= i_column; i++)
      {
        if((i-1)>0)
        {
          S.getCell(columns[i-1]+($row+1)).value = i_start+(i-2);
          S.getCell(columns[i-1]+($row+1)).font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
          S.getCell(columns[i-1]+($row+1)).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        }
        S.getCell(columns[i-1]+$row).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'eeece1'} };
        S.getCell(columns[i-1]+($row+1)).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'eeece1'} };
      }

      S.getCell('A'+$row).value="Currency = "+this.model.inputs[0].model_currency;
      S.getCell('A'+$row).font = { color: { argb: '123F4E' }, bold: true, name:'Calibri'};
      S.getCell('A'+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;

      if(this.historical_years>0)
      {
        S.getCell('B'+$row).value="Historical Period";
        S.getCell('B'+$row).font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
        S.getCell('B'+$row).alignment = { vertical: 'middle', horizontal: 'center' };

        S.getCell(columns[1+this.historical_years]+$row).value="Projection Period";
        S.getCell(columns[1+this.historical_years]+$row).font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
        S.getCell(columns[1+this.historical_years]+$row).alignment = { vertical: 'middle', horizontal: 'center' };

        if(this.historical_years===2)
        S.mergeCells('B'+$row+':C'+$row);
      }
      else
      {
        S.getCell('B'+$row).value="Projection Period";
        S.getCell('B'+$row).font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
        S.getCell('B'+$row).alignment = { vertical: 'middle', horizontal: 'center' };
      }

      S.mergeCells(columns[1+this.historical_years]+($row)+':'+columns[i_column-1]+''+$row);

      for (let X= 1; X <= i_column; X++)
      S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT;

      $row++;

      for (let X= 1; X <= i_column; X++)
      S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT;

      $row++;

      if(this.business_stage==='ideation')
      {

        S.getCell(columns[0]+$row).value     = 'Revenue Growth';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT
        $row++;
        S.getCell(columns[0]+$row).value     = 'Gross Profit Margin';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT
        $row++;
        S.getCell(columns[0]+$row).value     = 'EBITDA Margin';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT
        $row++;
        S.getCell(columns[0]+$row).value     = 'Tax Rate';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT

        $row = $row-3;

        for (let $col=1; $col <= this.historical_years; $col++)
        {
          S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[$col-1].growthPerc);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row++;
          S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[$col-1].grossProfitMargin);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row++;
          S.getCell(columns[$col]+$row).value     = this.formatted(this.historics[$col-1].ebitdaMargin);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row++;
          S.getCell(columns[$col]+$row).value     = this.formatted(this.model.inputs[0].tax_rate);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row = $row-3;
        }

        $col = this.historical_years+1;

        S.getCell(columns[$col]+$row).value     = this.formatted(this.current.growthPerc);
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.current.grossProfitMargin);
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.current.ebitdaMargin);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.model.inputs[0].tax_rate);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT

        $row = $row-3;

        $col_global = $col;

        for (let $col_local = 1; $col_local < this.projection_years; $col_local++)
        {
          $col = $col_global+$col_local;

          S.getCell(columns[$col]+$row).value     = this.formatted(this.projections[$col_local].growthPerc);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row++;
          S.getCell(columns[$col]+$row).value     = this.formatted(this.projections[$col_local].grossProfitMargin);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row++;
          S.getCell(columns[$col]+$row).value     = this.formatted(this.projections[$col_local].ebitdaMargin);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row++;
          S.getCell(columns[$col]+$row).value     = this.formatted(this.model.inputs[0].tax_rate);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT

          $row = $row-3;
        }

        $row += 3;

        for (let X= 1; X <= i_column; X++)
        S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT

      }

      if(this.business_stage==='early traction')
      {
        S.getCell(columns[0]+$row).value     = 'Opening cash';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT
        $row++;
        S.getCell(columns[0]+$row).value     = 'Operating cash flow';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT
        $row++;
        S.getCell(columns[0]+$row).value     = 'Cash from financing';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT
        $row++;
        S.getCell(columns[0]+$row).value     = 'Net Cashflow';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT
        $row++;
        S.getCell(columns[0]+$row).value     = 'Closing cash';
        S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT

        $row = $row-4;

        for (let $col=1; $col <= this.historical_years; $col++)
        {

          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row++;

          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row++;

          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row++;

          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row++;

          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;

          $row = $row-4;

        }

        $col = this.historical_years+1;

        S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.balance_sheet_calculation[0].opening_cash);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.balance_sheet_calculation[0].cashflow);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.balance_sheet_calculation[0].financing);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.balance_sheet_calculation[0].net_cashflow);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;
        S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.balance_sheet_calculation[0].closing_cashflow);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;

        $row = $row-4;

        $col_global = $col;

        for (let $col_local = 0; $col_local <= this.projection_years; $col_local++)
        {

          $col = $col_global+$col_local;

          S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.balance_sheet_calculation[$col_local].opening_cash);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.balance_sheet_calculation[$col_local].cashflow);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.balance_sheet_calculation[$col_local].financing);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.balance_sheet_calculation[$col_local].net_cashflow);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.balance_sheet_calculation[$col_local].closing_cashflow);
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          $row = $row-4;
        }

        $row = $row+4;

        for (let X= 1; X <= i_column; X++)
        {
          S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT
        }

        $row++;

        S.mergeCells('A'+$row+':'+columns[i_column-1]+$row);

        for (let X= 1; X <= i_column; X++)
        {
          S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT;
        }

        $row++;

        for (let i = 1; i <= i_column; i++)
        {

          if((i-1)>0)
          {
            S.getCell(columns[i-1]+($row+1)).value = i_start+(i-2);
            S.getCell(columns[i-1]+($row+1)).font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
            S.getCell(columns[i-1]+($row+1)).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          }

          S.getCell(columns[i-1]+$row).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'eeece1'} };
          S.getCell(columns[i-1]+($row+1)).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'eeece1'} };
        }

        S.getCell('A'+$row).value="Currency = "+this.model.inputs[0].model_currency;
        S.getCell('A'+$row).font = { color: { argb: '123F4E' }, bold: true, name:'Calibri'};
        S.getCell('A'+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;

        if(this.historical_years>0)
        {
          S.getCell('B'+$row).value="Historical Period";
          S.getCell('B'+$row).font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
          S.getCell('B'+$row).alignment = { vertical: 'middle', horizontal: 'center' };

          S.getCell(columns[1+this.historical_years]+$row).value="Projection Period";
          S.getCell(columns[1+this.historical_years]+$row).font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
          S.getCell(columns[1+this.historical_years]+$row).alignment = { vertical: 'middle', horizontal: 'center' };

          if(this.historical_years===2)
          {
            S.mergeCells('B'+$row+':C'+$row);
          }
        }
        else
        {
          S.getCell('B'+$row).value="Projection Period";
          S.getCell('B'+$row).font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
          S.getCell('B'+$row).alignment = { vertical: 'middle', horizontal: 'center' };
        }

        S.mergeCells(columns[1+this.historical_years]+($row)+':'+columns[i_column-1]+''+$row);

        for (let X= 1; X <= i_column; X++)
        S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT;

        $row++;
        for (let X= 1; X <= i_column; X++)
        S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT;

        $row++;
        _row = $row;

        S.getCell(columns[0]+$row).value     = 'Net Burn';
        S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT
        $row++;
        S.getCell(columns[0]+$row).value     = 'Number of Customers (Ending)';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;
        S.getCell(columns[0]+$row).value     = 'Number of Customers (Avg)';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;
        S.getCell(columns[0]+$row).value     = 'Average Churn (%)';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;
        S.getCell(columns[0]+$row).value     = 'Customer Lifetime (mths)';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;
        S.getCell(columns[0]+$row).value     = 'Retention Rate (%)';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;
        S.getCell(columns[0]+$row).value     = 'Average Revenue per Account';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;
        S.getCell(columns[0]+$row).value     = 'Lifetime Value (LTV)';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;
        S.getCell(columns[0]+$row).value     = 'Customer Acquisition Cost';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;
        S.getCell(columns[0]+$row).value     = 'LTV / Customer Acquisition Cost';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;

        if(this.PnlRevenueMoSa==true)
        {
          $row++;
          S.getCell(columns[0]+$row).value     = 'Total number of customers';
          S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
          S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[0]+$row).value     = 'Monthly Recurring Revenue (MRR)';
          S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
          S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[0]+$row).value     = 'Annual Recurring Revenue';
          S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
          S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[0]+$row).value     = 'Average Revenue per Account (ARPA / month)';
          S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
          S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
        }

        if(this.PnlRevenueMoPr==true)
        {
          $row++;
          S.getCell(columns[0]+$row).value     = 'Units sold';
          S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
          S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[0]+$row).value     = 'Average pricing';
          S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
          S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;
        }

        $row = _row;

        for (let $col=1; $col <= this.historical_years; $col++)
        {
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
          $row++;
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_NORMAL;
          $row++;
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;
          S.getCell(columns[$col]+$row).value     = '— ';
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          if(this.PnlRevenueMoSa==true)
          {

            $row++;
            S.getCell(columns[$col]+$row).value     = '— ';
            S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
            S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
            S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

            $row++;
            S.getCell(columns[$col]+$row).value     = '— ';
            S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
            S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
            S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT

            $row++;
            S.getCell(columns[$col]+$row).value     = '— ';
            S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
            S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
            S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT

            $row++;
            S.getCell(columns[$col]+$row).value     = '— ';
            S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
            S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
            S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT

          }
          if(this.PnlRevenueMoPr==true)
          {

            $row++;

            S.getCell(columns[$col]+$row).value     = '— ';
            S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
            S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
            S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
            $row++;

            S.getCell(columns[$col]+$row).value     = '— ';
            S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
            S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
            S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          }

          $row = _row;

        }

        $col = this.historical_years+1;

        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.balance_sheet_calculation[0].net_burn);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        //S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[0].num_customer_ending);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_NORMAL;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[0].num_customer_average);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[0].average_chun);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[0].lifetime_customer);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[0].retention_rate);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[0].average_account_revenue);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[0].LTV);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[0].CAC);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[0].LTV_CAC);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

        if(this.PnlRevenueMoSa==true)
        {
          S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.kpi_calculation?this.meta.kpi_calculation[0].total_customers:0);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.kpi_calculation?this.meta.kpi_calculation[0].average_mrr:0);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.kpi_calculation?this.meta.kpi_calculation[0].arr:0);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT
          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.kpi_calculation?this.meta.kpi_calculation[0].mrr_per_total_customers:0);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

        }
        if(this.PnlRevenueMoPr==true)
        {

          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.kpi_calculation?this.meta.kpi_calculation[0].units_sold:0);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.kpi_calculation?this.meta.kpi_calculation[0].average_pricing:0);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

        }

        $row = _row;

        $col_global = $col;

        for (let $col_local = 0; $col_local <= this.projection_years; $col_local++)
        {

          $col = $col_global+$col_local;

          S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.balance_sheet_calculation[$col_local].net_burn);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;
          S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;

          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[$col_local].num_customer_ending);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[$col_local].num_customer_average);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[$col_local].average_chun);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[$col_local].lifetime_customer);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[$col_local].retention_rate);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[$col_local].average_account_revenue);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[$col_local].LTV);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[$col_local].CAC);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          $row++;

          S.getCell(columns[$col]+$row).value     = this.formatted(JSON.parse(this.model.calculation[0].metrics)[$col_local].LTV_CAC);
          S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          if(this.PnlRevenueMoSa==true)
          {

            $row++;

            S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.kpi_calculation?this.meta.kpi_calculation[$col_local].total_customers:0);
            S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
            S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
            S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

            $row++;

            S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.kpi_calculation?this.meta.kpi_calculation[$col_local].average_mrr:0);
            S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
            S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
            S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT

            $row++;

            S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.kpi_calculation?this.meta.kpi_calculation[$col_local].arr:0);
            S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
            S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
            S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT

            $row++;

            S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.kpi_calculation?this.meta.kpi_calculation[$col_local].mrr_per_total_customers:0);
            S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
            S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
            S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          }
          if(this.PnlRevenueMoPr==true)
          {

            $row++;

            S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.kpi_calculation?this.meta.kpi_calculation[$col_local].units_sold:0);
            S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
            S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
            S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

            $row++;

            S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.kpi_calculation?this.meta.kpi_calculation[$col_local].average_pricing:0);
            S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
            S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);
            S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          }

          $row = _row;

        }

        if(this.PnlRevenueMoSa==true)
        {
          $row += 4;
        }
        if(this.PnlRevenueMoPr==true)
        {
          $row += 2;
        }

        $row += 9;

        for (let X= 1; X <= i_column; X++)
        {
          S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT
        }

        $row++;

        S.mergeCells('A'+$row+':'+columns[i_column-1]+$row);

        for (let X= 1; X <= i_column; X++)
        {
          S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT
        }

        $row++;

        for (let i = 1; i <= i_column; i++)
        {

          if((i-1)>0)
          {
            S.getCell(columns[i-1]+($row+1)).value = i_start+(i-2);
            S.getCell(columns[i-1]+($row+1)).font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
            S.getCell(columns[i-1]+($row+1)).alignment = this.$_XLS_ALIGNMENT_RIGHT;
          }

          S.getCell(columns[i-1]+$row).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'eeece1'} };
          S.getCell(columns[i-1]+($row+1)).fill = { type: 'pattern', pattern:'solid', fgColor:{argb:'eeece1'} };
        }

        S.getCell('A'+$row).value="Currency = "+this.model.inputs[0].model_currency;
        S.getCell('A'+$row).font = { color: { argb: '123F4E' }, bold: true, name:'Calibri'};
        S.getCell('A'+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;

        if(this.historical_years>0)
        {
          S.getCell('B'+$row).value="Historical Period";
          S.getCell('B'+$row).font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
          S.getCell('B'+$row).alignment = { vertical: 'middle', horizontal: 'center' };

          S.getCell(columns[1+this.historical_years]+$row).value="Projection Period";
          S.getCell(columns[1+this.historical_years]+$row).font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
          S.getCell(columns[1+this.historical_years]+$row).alignment = { vertical: 'middle', horizontal: 'center' };

          if(this.historical_years===2)
          {
            S.mergeCells('B'+$row+':C'+$row);
          }
        }
        else
        {
          S.getCell('B'+$row).value="Projection Period";
          S.getCell('B'+$row).font = {color: { argb: '123F4E' },bold: true,name:'Calibri'};
          S.getCell('B'+$row).alignment = { vertical: 'middle', horizontal: 'center' };
        }

        S.mergeCells(columns[1+this.historical_years]+($row)+':'+columns[i_column-1]+''+$row);

        for (let X= 1; X <= i_column; X++)
        {
          S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT;
        }

        $row++;

        for (let X= 1; X <= i_column; X++)
        {
          S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT;
        }

        $row++;

        S.getCell(columns[0]+$row).value     = 'Runway';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;

        $row++;

        S.getCell(columns[0]+$row).value     = 'Average Net Burn';
        S.getCell(columns[0]+$row).alignment = this.$_XLS_ALIGNMENT_LEFT;
        S.getCell(columns[0]+$row).fill      = this.setXlsRow($row);
        S.getCell(columns[0]+$row).border    = this.$_XLS_BORDER_RIGHT;

        $row = $row-1;

        for (let $col=1; $col <= this.historical_years; $col++)
        {

          S.getCell(columns[$col]+$row).fill = this.setXlsRow($row);

          $row++;

          S.getCell(columns[$col]+$row).fill = this.setXlsRow($row);

          $row = $row-1;

        }

        $col = this.historical_years+1;

        S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.runway_calculation.runway);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).font      = this.$_XLS_FONT_BOLD;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);

        $row++;

        S.getCell(columns[$col]+$row).value     = this.formatted(this.meta.runway_calculation.average_net_burn);
        S.getCell(columns[$col]+$row).alignment = this.$_XLS_ALIGNMENT_RIGHT;
        S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);

        $row = $row-1 ;

        $col_global = $col;

        for (let $col_local = 0; $col_local <= this.projection_years; $col_local++)
        {

          $col = $col_global+$col_local;

          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);

          if($col_local===this.projection_years)
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          $row++;

          S.getCell(columns[$col]+$row).fill      = this.setXlsRow($row);

          if($col_local===this.projection_years)
          S.getCell(columns[$col]+$row).border    = this.$_XLS_BORDER_RIGHT;

          $row = $row-1;

        }

        $row = $row+1;

        for ( let X= 1; X <= i_column; X++ )
        {
          if(X===i_column||X===1)
          {
            S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM_RIGHT
          }
          else
          {
            S.getCell(columns[X-1]+$row).border = this.$_XLS_BORDER_BOTTOM
          }
        }

      }

      workbook
      .xlsx
      .writeBuffer()
      .then((buffer)=>
        {
          fileSaver(new Blob([buffer],{type:"application/octet-stream"}), 'Model-'+this.model.calculation[0].id+'.xls');
        }
      );

    },
  },
  async created(){
    this.getFinancialDaTa();
    this.getScale();

      this.PnlRevenueData = this.model.revenue_input;
      this.PnlRevenueList = this.model.revenue;

      this.PnlRevenueMode = false;
      this.PnlRevenueMoPr = false;
      this.PnlRevenueMoSa = false;

      for(const revenue of this.PnlRevenueData)
      {
        if(revenue.type==='product')
        {
          this.PnlRevenueMoPr = true;
          this.PnlRevenueMode = true;
        }
        if(revenue.type==='saas')
        {
          this.PnlRevenueMoSa = true;
          this.PnlRevenueMode = true;
        }
      }
      this.PnlCostCOGSData = this.model.costs.cogs.cost;
      this.PnlCostCOGSList = this.model.costs.cogs.analysis;
      this.PnlCostSGAData  = this.model.costs.sga.cost;
      this.PnlCostSGAList  = this.model.costs.sga.analysis;
      this.PnlCostDAData   = this.model.costs.dfa.cost;
      this.PnlCostDAList   = this.model.costs.dfa.analysis;
      this.PnlCostNIData   = this.model.costs.interest.cost;
      this.PnlCostNIList   = this.model.costs.interest.analysis;
      this.ini()
  }
}

</script>

<style lang="scss" scoped>
.scroll{
  overflow-x: scroll;
}
.table-conatiner {
  display: flex;
  margin: auto;
  min-width: 1200px;
  overflow: hidden;
  flex-direction: row;
  // flex-direction: row-reverse;
  justify-content: center;
  align-items: start;
  flex-flow: row;
  border: 1px solid #ccc;
}
.table-row {
  display: flex;
  // height: 1000px;
  width:300px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  text-align: center;
  

}
.table-cell {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  text-align: left;
  // text-align: center;
}
.years {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;

}
.year{
  // width: 50%;
  padding:10px 20px;
  text-align: center;
  height: 50px;
  border-right: 1px solid #ccc ;
}
.year-value{
  // width: 50%;
  padding: 10px;
  text-align: center;
  border-right: 1px solid #ccc ;
}
.row-index {
  width: 40%;
  border-right: 1px solid #ccc;
  color: rgb(13, 112, 66);
}
.row-value {
  width: 60%;
  border-right: 1px solid #ccc;
}
.table-head{
  font-weight: bold;
  height: 100px;
  color: rgb(13, 112, 66);
  background-color: #f1f1f1;
}
.table-wrap {
  display: flex;
  padding: 0;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  border-bottom:1px solid #ccc;
}
// .table-wrap-cell {
//   width: 100%;
//   border-bottom:1px solid #ccc;

// }
.cell{
  width: 100%;
  height: 50px;
  border-bottom:1px solid #ccc;
}
span{
  width: 100%;
  text-align: center;
  padding: 5px;
}
.gray{
   background-color: #f1f1f1;
}
.watermark
{
  position: relative;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:after
  {
    top:13px;
    left:21px;
    right:21px;
    bottom:23px;
    content:'';
    z-index: 100;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../assets/images/watermark.svg")
  }




}
.subject{
  font-size: 20px;
  font-weight: bold;
  color: rgb(0, 124, 48);
  margin-bottom: 20px;
}

.chart-subject{
  font-size: 20px;
  color: rgb(0, 124, 48);
}


</style>
