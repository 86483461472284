const Symbols=[];

Symbols['USD']='$';
Symbols['CAD']='$';
Symbols['EUR']='€';
Symbols['AUD']='$';
Symbols['CHF']='Fr';
Symbols['GBP']='£';
Symbols['JPY']='¥';
Symbols['KES']='/-';
Symbols['ZAR']='R';
Symbols['NGN']='₦';

export default Symbols;
